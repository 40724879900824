@each $color, $value in $backgroundColors{
  .bg-#{$color}{
    background-color:$value!important;
  }
}

@each $color, $value in $TextColors{
  .text-#{$color}{
    color:$value!important;
  }
}

// .bg-op2{
//     &--java-2{
//         background-color: rgba($java-2, 0.2);
//     }
//     &--primary{
//         background-color: rgba($primary, 0.2);
//     }
//     &--victoria{
//         background-color: rgba($victoria, 0.2);
//     }
//     &--dodger-blue{
//         background-color: rgba($dodger-blue, 0.2);
//     }
//     &--caribbean-green{
//         background-color: rgba($caribbean-green, 0.2);
//     }
//     &--yellow{
//         background-color: rgba($yellow, 0.2);
//     }
// }
@each $color, $value in $opacity-colors{
  .bg-op2--#{$color}{
    background-color: rgba($value, 0.2);
  }
}